<template>
  <div class="flix-form-group">
    <a
      href="#"
      class="flix-html-a flix-text-darkgrey"
      style="justify-content: flex-start; gap: 5px; padding: 30px 12px;"
      @click.prevent="addService"
      ><icon id="plus" />
      {{ $t("services.editor.add", { count: getServices().length + 1 + "." }) }}
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    addService() {
      var service = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      var short = [this.$t("services.tags.category") + " 1"];
      if (service.length >= 1) {
        short = service[service.length - 1].short;
      }
      service.push({
        ID: new Date().getTime(),
        title: this.$t("services.title") + " " + (service.length + 1),
        description: "",
        time: "60",
        price: "",
        short: short,
        img: ""
      });
      this.$store.commit("service/editService", service.length - 1);
      this.$store.commit("service/prefetch", service);
      this.$store.commit("service/setUpdateKey");
    },
    getServices() {
      return JSON.parse(JSON.stringify(this.$store.state.service.unsaved));
    }
  }
};
</script>
<style lang="sass" scoped></style>
